import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { getRiskValue } from "@/components/GroupPageTable/Components/EstimatedRiskCell/utils";
import { RiskChange, SuggestedStatusLevel } from "@/components/RiskStatus";
import { Activity_NoteFragment } from "@/gql/graphql";

import { Activity, OnboardingEvaluated } from "./types";

interface ActivityDetailSummaryProps {
    activity: Activity;
    socialRisk: SuggestedStatusLevel | undefined;
}

export const ActivityDetailSummary: React.FC<ActivityDetailSummaryProps> = ({ activity, socialRisk }) => {
    switch (activity.type) {
        case "Note":
            return (
                <Typography variant="textXs" sx={{ color: (theme) => theme.palette.tokens?.text.textBody }}>
                    <FormattedMessage defaultMessage="Added a note" description="Note activity-type summary" />
                </Typography>
            );
        case "OnboardingInitiated":
            return (
                <Typography variant="textXs" sx={{ color: (theme) => theme.palette.tokens?.text.textBody }}>
                    <FormattedMessage
                        defaultMessage="Initiated onboarding"
                        description="Onboarding Initiated activity summary"
                    />
                </Typography>
            );
        case "OnboardingEvaluated": {
            const noteContent = activity.content as OnboardingEvaluated;
            const status = noteContent.status;
            if (status === "APPROVED") {
                return (
                    <Typography variant="textXs" sx={{ color: (theme) => theme.palette.tokens?.text.textBody }}>
                        <FormattedMessage
                            defaultMessage="Approved this supplier"
                            description="Onboarding approval activity summary"
                        />
                    </Typography>
                );
            } else if (status === "REJECTED") {
                return (
                    <Typography variant="textXs" sx={{ color: (theme) => theme.palette.tokens?.text.textBody }}>
                        <FormattedMessage
                            defaultMessage="Rejected this supplier"
                            description="Onboarding rejection activity summary"
                        />
                    </Typography>
                );
            } else {
                return null;
            }
        }
        case "Risk": {
            const noteContent = activity.content as Activity_NoteFragment;
            const prevVal = noteContent.metadata?.prevValue ? getRiskValue(noteContent.metadata?.prevValue) : undefined;
            const newVal = noteContent.metadata?.newValue ? getRiskValue(noteContent.metadata?.newValue) : undefined;
            return <RiskChange prevVal={prevVal} newVal={newVal} socialRisk={socialRisk} />;
        }
        default:
            return null;
    }
};
