import { ChevronDown, CircleSolid } from "@ignite-analytics/icons";
import { IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { GridRenderCellParams, GridTreeNodeWithRender, GridValidRowModel } from "@mui/x-data-grid-pro";
import { FormattedMessage } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { getLabel } from "@/lib/data/utils";

import { NACEOptions } from "../../../../lib/data/NaceOptions";

export function RenderNace(params: GridRenderCellParams<GridValidRowModel, unknown, unknown, GridTreeNodeWithRender>) {
    const { value, api, id, field } = params;
    const theme = useTheme();
    const isEditable = params.api.getColumn(field).editable;
    const industry = NACEOptions.find((industry) => industry.code === value);
    const color = (() => {
        switch (industry?.score) {
            case "veryLow":
            case "low":
                return theme.palette.tokens?.icon.iconSuccess;
            case "medium":
                return theme.palette.tokens?.icon.iconWarning;
            case "high":
            case "veryHigh":
                return theme.palette.tokens?.icon.iconError;
            default:
                return theme.palette.tokens?.icon.iconGray;
        }
    })();

    const label = industry ? getLabel(industry) : undefined;

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" height="100%">
            {industry ? (
                <Tooltip title={label} followCursor>
                    <Typography variant="textSm" sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                        {label}
                    </Typography>
                </Tooltip>
            ) : (
                <Typography variant="textSm">
                    <FormattedMessage defaultMessage="Select" />
                </Typography>
            )}
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                <CircleSolid sx={{ color: color }} />
                <NoPermissionTooltip
                    hasPermission={!!isEditable}
                    message={<FormattedMessage defaultMessage="Your permissions do not allow editing" />}
                >
                    <IconButton disabled={!isEditable} onClick={() => api.startCellEditMode({ id, field })}>
                        <ChevronDown />
                    </IconButton>
                </NoPermissionTooltip>
            </Stack>
        </Stack>
    );
}
