import { useQuery } from "@apollo/client";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { SelectOptions } from "@/gql/graphql";
import { NACEOptions } from "@/lib/data/NaceOptions";
import { useAlert } from "@/providers/Alerts";

import { Filter } from "../../types";
import { SelectFilterType } from "../SelectFilterType";

import { ListboxComponent, StyledPopper } from "./ListBoxComponent";

const getUniqueValuesForSupplierTableColumnDocument = graphql(`
    query Filter_GetUniqueValuesForSupplierTableColumn($input: GetUniqueValuesForSupplierTableColumnInput!) {
        getUniqueValuesForSupplierTableColumn(input: $input) {
            columnId
            values
        }
    }
`);

interface IncludeExcludeFilterProps {
    filter: Filter;
    handleUpdateFilter: (filter: Filter) => void;
}

export const IncludeExcludeFilter: React.FC<IncludeExcludeFilterProps> = ({ filter, handleUpdateFilter }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const emptyFields = formatMessage({ defaultMessage: "Empty fields" });

    function getNaceLabel(option: string) {
        if (option === emptyFields) return formatMessage({ defaultMessage: "Empty fields" });
        const nace = NACEOptions.find((n) => n.code === option.slice(0, 5));
        if (!nace) return option;
        const locale = navigator.language;

        if (locale === "nb" || locale === "no" || locale === "nn") {
            return `${nace.code}: ${nace.name_no}`;
        }
        return `${nace.code}: ${nace.name_en}`;
    }

    function getAssessmentLabel(option: string) {
        switch (option) {
            case "notSent":
                return formatMessage({ defaultMessage: "Not sent" });
            case "sent":
                return formatMessage({ defaultMessage: "Pending" });
            case "submitted":
                return formatMessage({ defaultMessage: "Submitted" });
            default:
                return option;
        }
    }

    const { data, refetch } = useQuery(getUniqueValuesForSupplierTableColumnDocument, {
        variables: {
            input: {
                columnId: filter.column?.id ?? "",
            },
        },
        skip: !filter.type,
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to load values for filter",
                    description: "Alert message when filter values could not be loaded",
                }),
                severity: "error",
            });
        },
    });

    const column = filter.column;

    const options = useMemo(() => {
        if (column?.type === "SELECT" || column?.type === "RISK") {
            const options = (column?.typeOptions as SelectOptions) ?? [];
            return [emptyFields, ...(options?.choices ?? [])];
        }
        if (filter.type === "include" || filter.type === "exclude") {
            return [emptyFields, ...(data?.getUniqueValuesForSupplierTableColumn.values ?? [])];
        }

        return data?.getUniqueValuesForSupplierTableColumn.values ?? [];
    }, [data, column, filter, emptyFields]);

    function getCustomLabel(option: string, columnType?: string): string {
        if (columnType === "NACE") {
            return getNaceLabel(option);
        }
        if (columnType === "ASSESSMENT_STATUS") {
            return getAssessmentLabel(option);
        }
        return option;
    }

    return (
        <>
            <Grid item xs={4}>
                <SelectFilterType filter={filter} handleUpdateFilter={handleUpdateFilter} />
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                    multiple
                    size="small"
                    disabled={!filter.type}
                    getLimitTagsText={(more) => `${more} selected`}
                    id={`filter-value-select-${filter.id}`}
                    options={options}
                    value={filter.includeExcludeValues ?? []}
                    ListboxComponent={ListboxComponent}
                    PopperComponent={StyledPopper}
                    autoHighlight
                    disableCloseOnSelect
                    onOpen={() => {
                        refetch();
                    }}
                    renderTags={() => {
                        return `${filter.includeExcludeValues?.length} selected`;
                    }}
                    renderOption={(props, option, state) =>
                        [props, getCustomLabel(option, column?.type), state.index] as React.ReactNode
                    }
                    onInputChange={(event, newInputValue, reason) => {
                        if (reason === "clear") {
                            handleUpdateFilter({
                                ...filter,
                                includeExcludeValues: [],
                            });
                        }
                    }}
                    onChange={(event, newValue, reason) => {
                        if (reason === "selectOption" || reason === "removeOption") {
                            handleUpdateFilter({
                                ...filter,
                                includeExcludeValues: newValue,
                            });
                        }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
        </>
    );
};
