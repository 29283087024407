import { useQuery } from "@apollo/client";
import { Category, Company } from "@ignite-analytics/icons";
import { Chip, ChipProps, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import { graphql } from "@/gql";
import { ProcurementEntityType } from "@/gql/graphql";
import { track, TRACK_PREFIX } from "@/lib/track";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

export type CategoryEntity = {
    id: string;
    type: ProcurementEntityType;
    label: string;
};

interface CategoryBarProps {
    supplierId: string;
}
export const CategoryBar: React.FC<CategoryBarProps> = ({ supplierId }) => {
    const { data } = useQuery(
        graphql(`
            query CategoryBar_GetSupplier($input: GetSupplierInput!) {
                getSupplier(input: $input) {
                    supplier {
                        id
                        businessUnits {
                            id
                            name
                        }
                        categories {
                            id
                            name
                        }
                    }
                }
            }
        `),
        {
            variables: {
                input: {
                    id: supplierId,
                },
            },
            skip: !supplierId,
        }
    );

    const categories = data?.getSupplier.supplier?.categories || [];
    const categoryEntities = categories.map((category) => ({
        id: category.id,
        type: ProcurementEntityType.Category,
        label: category.name,
    }));
    const businessUnits = data?.getSupplier.supplier?.businessUnits || [];
    const businessUnitEntities = businessUnits.map((businessUnit) => ({
        id: businessUnit.id,
        type: ProcurementEntityType.Department,
        label: businessUnit.name,
    }));

    return (
        <Stack direction="row" spacing={1}>
            {categoryEntities.length > 0 && <EntityChip entity={categoryEntities[0]} />}
            {categoryEntities.length > 1 && <MoreChip entities={categoryEntities.slice(1)} color="category" />}
            {businessUnitEntities.length > 0 && <EntityChip entity={businessUnitEntities[0]} />}
            {businessUnitEntities.length > 1 && (
                <MoreChip entities={businessUnitEntities.slice(1)} color="businessUnit" />
            )}
        </Stack>
    );
};

interface EntityChipProps {
    entity: CategoryEntity;
}

const EntityChip: React.FC<EntityChipProps> = ({ entity }) => {
    const navigate = useOutgoingNavigate();
    return (
        <Chip
            icon={entity.type == ProcurementEntityType.Category ? <Category /> : <Company />}
            label={entity.label}
            size="small"
            color={entity.type == ProcurementEntityType.Category ? "category" : "businessUnit"}
            onClick={() => {
                track(`${TRACK_PREFIX}: Navigated Header Category`, {
                    type: entity.type == ProcurementEntityType.Category ? "Category" : "Department",
                });
                navigate(
                    `/spend/${entity.type == ProcurementEntityType.Category ? "categories" : "business-units"}/${entity.id}/spend?perspective=SUPPLIER`
                );
            }}
        />
    );
};

interface MoreChipProps extends ChipProps {
    entities: CategoryEntity[];
}
const MoreChip: React.FC<MoreChipProps> = ({ entities, ...chipProps }) => {
    const navigate = useOutgoingNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        track(`${TRACK_PREFIX}: Clicked Header More Categories`);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Chip
                label={`+${entities.length}`}
                clickable
                color="primary"
                size="small"
                onClick={handleClick}
                {...chipProps}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                sx={{ marginTop: 1 }}
            >
                {entities.map((entity, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            track(`${TRACK_PREFIX}: Navigated Header Category`, {
                                type: entity.type == ProcurementEntityType.Category ? "Category" : "Department",
                            });
                            navigate(
                                `/spend/${entity.type == ProcurementEntityType.Category ? "categories" : "business-units"}/${entity.id}/spend?perspective=SUPPLIER`
                            );
                        }}
                        sx={{
                            "&:hover": {
                                cursor: entity.type == ProcurementEntityType.Category ? "pointer" : "default",
                            },
                        }}
                    >
                        <Stack direction="row" spacing={1} alignItems="center">
                            {entity.type == ProcurementEntityType.Category ? <Category /> : <Company />}
                            <Typography variant="textSm">{entity.label}</Typography>
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
