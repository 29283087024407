import { useQuery } from "@apollo/client";
import { ArrowLeft, X } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Typography,
    Grid2 as Grid,
    Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import React, { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { GroupDetailsModal_SupplierGroupFragment, SupplierFilterType } from "@/gql/graphql";
import { useCompanyCurrency } from "@/providers/CompanyCurrencyContext";

import { RiskEstimatesSelector } from "../RiskEstimatesSelector";
import { SpendRangeSelector } from "../SpendRangeSelector";

import { BusinessUnitChip } from "./BusinessUnitChip";
import { SpendCategoryChip } from "./SpendCategoryChip";

graphql(`
    fragment GroupDetailsModal_SupplierGroup on SupplierGroup {
        name
        filter {
            riskEstimates
            categories
            businessUnits
            spendFilter {
                columnId
                minRange
                maxRange
            }
            additionalFilters {
                type
                columnId
                minRange
                maxRange
                minDateISOString
                maxDateISOString
                includeExcludeValues
                includeBlanks
            }
        }
    }
`);

const getMinMaxQuery = graphql(`
    query GroupDetailsModal_MinMaxSpendRange($input: GetMinMaxForSupplierTableNumberColumn!) {
        getMinMaxForSupplierTableNumberColumn(input: $input) {
            min
            max
        }
    }
`);

const getSupplierTableColumnsQuery = graphql(`
    query GroupDetailsModal_GetSupplierTableMeta {
        getSupplierTableMeta {
            columns {
                id
                name
                type
                globalType
                typeOptions {
                    ... on ClassificationOptions {
                        groups {
                            id
                            value
                            level
                        }
                        __typename
                    }
                }
            }
        }
    }
`);

interface Props {
    open: boolean;
    onClose: () => void;
    group: GroupDetailsModal_SupplierGroupFragment;
}

export const GroupDetailsModal: React.FC<Props> = ({ open, onClose, group }) => {
    const { currency } = useCompanyCurrency();
    const { formatMessage } = useIntl();

    const spendColumnID = group.filter.spendFilter?.columnId ?? "";
    const { data: minMaxSpendData } = useQuery(getMinMaxQuery, {
        variables: {
            input: {
                columnId: spendColumnID,
            },
        },
        skip: !spendColumnID,
        onError: (error) => {
            Sentry.captureException(error, {
                tags: { app: "social-risk-app", message: "Failed to get min/max for spend column" },
            });
        },
    });
    const maxSpendRange = minMaxSpendData
        ? ([
              minMaxSpendData.getMinMaxForSupplierTableNumberColumn.min,
              minMaxSpendData.getMinMaxForSupplierTableNumberColumn.max,
          ] as const)
        : ([0, 0] as const);

    const { data: supplierTableColumnsData } = useQuery(getSupplierTableColumnsQuery, {
        onError: (error) => {
            Sentry.captureException(error, {
                tags: { app: "social-risk-app", message: "Failed to get supplier table columns for group detail" },
            });
        },
    });
    const columns = supplierTableColumnsData?.getSupplierTableMeta.columns ?? [];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle component={Stack} direction="row" gap={1.5} alignItems="center">
                    <IconButton onClick={onClose} size="small" color="secondary">
                        <ArrowLeft />
                    </IconButton>
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage
                            defaultMessage="Group details"
                            description="View custom group details modal title"
                        />
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={(theme) => ({
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500], // TODO: `close button tokens/close-secondary`
                        })}
                    >
                        <X />
                    </IconButton>
                </DialogTitle>
                <div /> {/* Hack to get the padding of the dialogcontent to appear */}
                <DialogContent>
                    <Stack gap={4} direction="column">
                        <Stack spacing={1} direction="column">
                            <Typography variant="textSm" fontWeight={500}>
                                <FormattedMessage
                                    defaultMessage="Supplier group name"
                                    description="Supplier group name label"
                                />
                            </Typography>
                            <Typography variant="textSm" fontWeight={400}>
                                {group.name}
                            </Typography>
                        </Stack>

                        {group.filter.categories && group.filter.categories.length > 0 && (
                            <Stack spacing={1} direction="column">
                                <Typography variant="textSm" fontWeight={500}>
                                    <FormattedMessage
                                        defaultMessage="Selected categories"
                                        description="Selected categories title"
                                    />
                                </Typography>
                                <Stack gap={1} direction="row" flexWrap="wrap">
                                    {group.filter.categories.map((categoryID) => (
                                        <SpendCategoryChip key={categoryID} spendCategoryID={categoryID} />
                                    ))}
                                </Stack>
                            </Stack>
                        )}

                        {group.filter.businessUnits && group.filter.businessUnits.length > 0 && (
                            <Stack spacing={1} direction="column">
                                <Typography variant="textSm" fontWeight={500}>
                                    <FormattedMessage
                                        defaultMessage="Selected business units"
                                        description="Selected business units title"
                                    />
                                </Typography>
                                <Stack gap={1} direction="row" flexWrap="wrap">
                                    {group.filter.businessUnits.map((businessUnitID) => (
                                        <BusinessUnitChip key={businessUnitID} businessUnitID={businessUnitID} />
                                    ))}
                                </Stack>
                            </Stack>
                        )}

                        {group.filter.riskEstimates && group.filter.riskEstimates.length > 0 && (
                            <Stack spacing={1} direction="column">
                                <Typography variant="textSm" fontWeight={500}>
                                    <FormattedMessage
                                        defaultMessage="Selected Ignite risk estimates"
                                        description="Selected Ignite risk estimates selector title"
                                    />
                                </Typography>

                                <RiskEstimatesSelector disabled riskEstimates={group.filter.riskEstimates} />
                            </Stack>
                        )}

                        {group.filter.spendFilter &&
                            group.filter.spendFilter.minRange != undefined &&
                            group.filter.spendFilter.maxRange != undefined && (
                                <Stack spacing={1} direction="column">
                                    <Typography variant="textSm" fontWeight={500}>
                                        <FormattedMessage
                                            defaultMessage="Spend range ({currency})"
                                            values={{ currency }}
                                            description="Spend range selector title"
                                        />
                                    </Typography>
                                    <Typography variant="textSm" fontWeight={400} color="primary">
                                        <FormattedMessage
                                            defaultMessage="The spend range shown corresponds to the last full year or latest spend."
                                            description="Spend range selector helper text"
                                        />
                                    </Typography>
                                    <SpendRangeSelector
                                        disabled
                                        range={[group.filter.spendFilter.minRange, group.filter.spendFilter.maxRange]}
                                        maxRange={maxSpendRange}
                                    />
                                </Stack>
                            )}

                        {group.filter.additionalFilters && group.filter.additionalFilters.length > 0 && (
                            <Stack gap={1} direction="column">
                                <Typography variant="textLg" fontWeight={500}>
                                    <FormattedMessage
                                        defaultMessage="Advanced options"
                                        description="Advanced options card title"
                                    />
                                </Typography>
                                {group.filter.additionalFilters.map((filter) => {
                                    const column = columns.find((c) => c.id === filter.columnId);
                                    if (!column) return null;

                                    const columnName = (
                                        <Autocomplete
                                            size="small"
                                            fullWidth
                                            disabled
                                            value={column.name}
                                            options={[column.name]}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    );

                                    switch (filter.type) {
                                        case SupplierFilterType.Exclude: // fallthrough
                                        case SupplierFilterType.Include: {
                                            const values: ReactNode[] = [...(filter.includeExcludeValues ?? [])];
                                            if (
                                                (filter.type === SupplierFilterType.Include && filter.includeBlanks) ||
                                                (filter.type === SupplierFilterType.Exclude && !filter.includeBlanks)
                                            ) {
                                                values.push(
                                                    formatMessage({
                                                        defaultMessage: "blank values",
                                                        description: "Filter detail 'blank values'",
                                                    })
                                                );
                                            }

                                            const filterType =
                                                filter.type === SupplierFilterType.Include
                                                    ? formatMessage({
                                                          defaultMessage: "includes",
                                                          description: "Filter detail include operator",
                                                      })
                                                    : formatMessage({
                                                          defaultMessage: "excludes",
                                                          description: "Filter detail exclude operator",
                                                      });

                                            return (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    spacing={1}
                                                    key={filter.columnId}
                                                    alignItems="top"
                                                >
                                                    <Grid size={4}>{columnName}</Grid>
                                                    <Grid size={4}>
                                                        <Autocomplete
                                                            size="small"
                                                            fullWidth
                                                            disabled
                                                            value={filterType}
                                                            options={[filterType]}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </Grid>
                                                    <Grid size={4}>
                                                        <Autocomplete
                                                            multiple
                                                            size="small"
                                                            disabled
                                                            options={values}
                                                            value={values}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                        case SupplierFilterType.Date: {
                                            const parseDate = (dateStr: string | undefined | null) => {
                                                if (!dateStr) return null;
                                                return dayjs(dateStr, "YYYY-MM-DD");
                                            };
                                            return (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    spacing={1}
                                                    key={filter.columnId}
                                                    alignItems="end"
                                                >
                                                    <Grid size={4}>{columnName}</Grid>
                                                    <Grid size={4}>
                                                        <DatePicker
                                                            disabled
                                                            label={
                                                                <FormattedMessage
                                                                    defaultMessage="From"
                                                                    description="Date filter 'From' label"
                                                                />
                                                            }
                                                            value={parseDate(filter.minDateISOString)}
                                                            format="MMM DD YYYY"
                                                            slotProps={{
                                                                textField: { size: "medium", fullWidth: true },
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid size={4}>
                                                        <DatePicker
                                                            disabled
                                                            label={
                                                                <FormattedMessage
                                                                    defaultMessage="To"
                                                                    description="Date filter 'To' label"
                                                                />
                                                            }
                                                            value={parseDate(filter.maxDateISOString)}
                                                            format="MMM DD YYYY"
                                                            slotProps={{
                                                                textField: { size: "medium", fullWidth: true },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                        case SupplierFilterType.Range:
                                            return (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    spacing={1}
                                                    key={filter.columnId}
                                                    alignItems="end"
                                                >
                                                    <Grid size={4}>{columnName}</Grid>
                                                    <Grid size={4}>
                                                        <TextField
                                                            label={
                                                                <FormattedMessage
                                                                    defaultMessage="Min"
                                                                    description="Filter detail 'Min' label"
                                                                />
                                                            }
                                                            size="medium"
                                                            type="number"
                                                            value={filter.minRange}
                                                            fullWidth
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid size={4}>
                                                        <TextField
                                                            label={
                                                                <FormattedMessage
                                                                    defaultMessage="Max"
                                                                    description="Filter detail 'Max' label"
                                                                />
                                                            }
                                                            size="medium"
                                                            type="number"
                                                            value={filter.maxRange}
                                                            fullWidth
                                                            disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                            );
                                        default:
                                            filter.type satisfies never; // Ensure exhaustive switch
                                    }
                                })}
                            </Stack>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Stack justifyContent="end" direction="row" width="100%">
                        <Button color="secondary" onClick={onClose} size="small">
                            <FormattedMessage
                                defaultMessage="Close"
                                description="View supplier group details 'Close' button"
                            />
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
};
