import { Dot } from "@ignite-analytics/icons";
import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

// TODO prop in info about updatedAt for note activities
export const TimeStamp: React.FC<{ timestamp: string }> = ({ timestamp }) => {
    return (
        <Stack direction="row" alignItems="center">
            <Typography variant="textXs" sx={{ color: (theme) => theme.palette.tokens?.text.textBody }}>
                {dayjs(timestamp).format("DD MMM YYYY")}
            </Typography>
            <Dot fontSize="large" color="inherit" />
            <Typography variant="textXs" sx={{ color: (theme) => theme.palette.tokens?.text.textBody }}>
                {dayjs(timestamp).format("HH:mm")}
            </Typography>
        </Stack>
    );
};
