import { ChevronDown } from "@ignite-analytics/icons";
import { Box, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { StatusChip, SuggestedStatusChip } from "@/components/RiskStatus";
import { graphql } from "@/gql";
import { EstimatedRiskCell_SupplierFragment } from "@/gql/graphql";
import { track, TRACK_PREFIX } from "@/lib/track";

import { NON_MISSING_RISK_STATUSES } from "../../utils";
import { EditMenu } from "../EditMenu/EditRow";

import { ReasonIcon } from "./ReasonIcon";
import { RiskReasonModal } from "./RiskReasonModal";
import { getRiskValue } from "./utils";

graphql(`
    fragment EstimatedRiskCell_Supplier on Supplier {
        id
        socialRiskScore {
            value
        }
        ...ReasonTooltip_Supplier
        notes {
            ...ReasonTooltip_Note
        }
        ...EditMenu_Supplier
    }
`);

export const EstimatedRiskCell: React.FC<{
    supplier: EstimatedRiskCell_SupplierFragment;
    groupId: string;
    onChange(
        columnId: string,
        supplierId: string,
        newValue: string | undefined,
        updateReason: string | undefined
    ): void;
    isEditor: boolean;
    campaignId: string | null | undefined;
    startPolling: (pollInterval: number) => void;
    stopPolling: () => void;
    groupColumnIdsAdded: string[];
    refetch: () => void;
}> = ({
    supplier,
    groupId,
    onChange,
    isEditor,
    campaignId,
    startPolling,
    stopPolling,
    refetch,
    groupColumnIdsAdded,
}) => {
    const hasManualRisk =
        supplier.socialRiskScore &&
        supplier.socialRiskScore.value !== "" &&
        supplier.socialRiskScore.value !== "MISSING"; // We don't return "MISSING" yet, but might in the future
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [newValue, setNewValue] = useState<string | undefined>(undefined);
    const [approve, setApprove] = useState<boolean>(false);
    const openSelect = Boolean(anchorEl);
    const [openReasonModal, setOpenReasonModal] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRiskChoice = (value: string | undefined) => {
        // check if status is approved -> manual risk same as estimated risk
        setApprove(!hasManualRisk && !!supplier.risk?.social && value === getRiskValue(supplier.risk.social));
        setNewValue(value);
        setOpenReasonModal(true);
    };

    const handleSelect = (value: string | undefined) => {
        handleRiskChoice(value);
        handleClose();
    };

    const handleOnChange = (reason: string | undefined) => {
        track(`${TRACK_PREFIX}: Changed Risk Status`, { status: newValue });
        onChange("social_risk_score", supplier.id, newValue, reason);
    };

    const riskValue = hasManualRisk
        ? getRiskValue(supplier.socialRiskScore?.value ?? "MISSING")
        : getRiskValue(supplier.risk?.social ?? "MISSING");
    const riskNotes = supplier.notes
        .filter((note) => note.type === "risk")
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    return (
        <Stack direction="row" alignItems="center" justifyContent="flex-end" height="100%" width="100%">
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
            >
                <Stack
                    direction="row"
                    spacing={0.5}
                    width="100%"
                    justifyContent={riskNotes.length > 0 ? "space-between" : "inherit"}
                >
                    {riskNotes.length > 0 && <ReasonIcon supplier={supplier} note={riskNotes[0]} />}
                    {hasManualRisk ? <StatusChip status={riskValue} /> : <SuggestedStatusChip status={riskValue} />}
                </Stack>
            </Box>
            <NoPermissionTooltip
                hasPermission={isEditor}
                placement="top"
                message={<FormattedMessage defaultMessage="Your permissions do not allow editing" />}
            >
                <IconButton onClick={handleClick} disabled={!isEditor}>
                    <ChevronDown />
                </IconButton>
            </NoPermissionTooltip>
            <Menu anchorEl={anchorEl} open={openSelect} onClose={handleClose}>
                {NON_MISSING_RISK_STATUSES.map((value) => (
                    <MenuItem key={value} onClick={() => handleSelect(value)}>
                        <StatusChip status={value} />
                    </MenuItem>
                ))}
                {supplier.socialRiskScore?.value && (
                    <MenuItem
                        onClick={() => handleSelect(undefined)}
                        sx={{ borderTop: "1px solid", borderColor: "divider" }}
                    >
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Reset status" />
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
            <EditMenu
                supplier={supplier}
                groupId={groupId}
                isEditor={isEditor}
                campaignId={campaignId}
                startPolling={startPolling}
                stopPolling={stopPolling}
                groupColumnIdsAdded={groupColumnIdsAdded}
                refetch={refetch}
                onRiskChange={handleRiskChoice}
            />
            <RiskReasonModal
                open={openReasonModal}
                onClose={() => setOpenReasonModal(false)}
                type={approve ? "approve" : "change"}
                onChange={handleOnChange}
            />
        </Stack>
    );
};
