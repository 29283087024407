import { useQuery } from "@apollo/client";
import { PaperAirplane } from "@ignite-analytics/icons";
import { Button, Divider, Skeleton, Stack, Theme, Tooltip } from "@mui/material";
import { DataGridPro, GridCellParams, GridToolbarContainer } from "@mui/x-data-grid-pro";
import * as Sentry from "@sentry/react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { GroupPageTable_SupplierGroupFragment, GroupPageTable_UserFragment } from "@/gql/graphql";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

import { CampaignCreationDialog } from "../CampaignCreationDialog";

import { GridToolbarSearchField } from "./GridToolbarSearchField";
import { GridToolbarTotalItemsContainer } from "./GridToolbarTotalItemsContainer";
import { columnGroupingModel, useGetColumns } from "./GroupPageDefinitions";
import { TableData, toSupplierRows } from "./utils";

graphql(`
    fragment GroupPageTable_SupplierGroup on SupplierGroup {
        id
        supplierIDs
        additionalColumns
        name
        campaignId
    }
`);

const getSuppliersQuery = graphql(`
    query GetSuppliers($input: GetSuppliersByIdsInput!) {
        getSuppliersByIds(input: $input) {
            suppliers {
                ...GroupPageTable_Supplier
            }
        }
    }

    fragment GroupPageTable_Supplier on Supplier {
        id
        name
        country
        productionCountries
        hasCodeOfConduct
        spend {
            lastAvailableYear {
                year
                value
            }
        }
        nace
        customFields {
            fieldId
            dataJson
        }
        risk {
            social
        }
        socialRiskScore {
            value
        }
        onboarding {
            status
            approverId
        }
        ...EstimatedRiskCell_Supplier
    }
`);

const getAssessmentsInCampaignQuery = graphql(`
    query GetAssessmentsInCampaign_GroupPageTable($input: GetCampaignInput!) {
        getCampaign(input: $input) {
            campaign {
                assessments {
                    ...GroupPageTable_Assessment
                }
            }
        }
    }
    fragment GroupPageTable_Assessment on Assessment {
        id
        supplierId
        submittedAt
        score
    }
`);

graphql(`
    fragment GroupPageTable_User on RoleUser {
        id
        fullName
    }
`);

const getUsersQuery = graphql(`
    query Onboarding_GetUsers($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                ...GroupPageTable_User
            }
        }
    }
`);

const getLatestSpendInfoQuery = graphql(`
    query UseGetColumns_GetLatestSpendInfo {
        getLatestSpendInfo {
            latestSpendInfo {
                columnId
                columnName
            }
        }
    }
`);

const tableStyling = {
    minHeight: "500px",
    overflowX: "scroll",
    "& .MuiDataGrid-columnSeparator": {
        display: "none",
    },
    "& .risk-header": {
        backgroundColor: (theme: Theme) => theme.palette.tokens?.chip.primary,
        borderTop: "2px solid #069cdf",
    },
    /* come back to this with new header captionining -- some white better than the gray block */
    ".MuiDataGrid-columnHeaders .MuiDataGrid-filler": {
        backgroundColor: "#fff",
    },
    "& .MuiDataGrid-columnHeader--filledGroup": {
        backgroundColor: "#fff",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer.MuiDataGrid-withBorderColor": {
        borderBottom: "none",
    },
    "& .MuiDataGrid-columnHeader--emptyGroup": {
        backgroundColor: "#fff",
    },
    "& .MuiDataGrid-columnHeader--pinnedRight": {
        backgroundColor: (theme: Theme) => theme.palette.background.backgroundSecondary,
    },
    "& .name-header": {
        backgroundColor: (theme: Theme) => theme.palette.background.backgroundSecondary,
    },
    "& .MuiDataGrid-columnHeader--emptyGroup.MuiDataGrid-columnHeader--pinnedRight": {
        backgroundColor: "#ffffff",
        borderLeft: "0px",
    },
    "& .MuiDataGrid-virtualScroller--hasScrollX": {
        // padding to accommodate scrollbar. The formula is how the scrollbar size is calculated in DataGrid
        paddingBottom: "calc(max(var(--DataGrid-scrollbarSize), 14px))",
    },
    "& .MuiDataGrid-scrollbar--vertical": {
        // may need attention (buggy extra space appears when mouse connected on Mac...sometimes)
        // hiding this for now as table don't have a max height
        overflow: "hidden !important",
    },
    "& .MuiTablePagination-spacer": {
        order: 2,
    },
    "& .MuiDataGrid-cell": {
        height: "auto",
    },
    "& .MuiDataGrid-cell[data-field='productionCountries']": {
        overflow: "visible",
    },
    "& .greyed-out": {
        color: "#b0b0b0",
        "& > div, & > span": {
            "& circle, & path": {
                fill: "#a0a0a0",
            },
        },
    },
} as const;

const initialState = {
    pagination: { paginationModel: { pageSize: 25 } },
    sorting: {
        sortModel: [{ field: "spend", sort: "desc" as const }],
    },
};
const pageSizeOptions = [10, 25, 50];
const pinnedColumns = { left: ["name"], right: ["estimatedRisk"] };
const getRowId = (row: TableData) => row.id;

interface GroupPageTableProps {
    group: GroupPageTable_SupplierGroupFragment;
    isEditor: boolean;
}

export const GroupPageTable: React.FC<GroupPageTableProps> = ({ group, isEditor }) => {
    const [campaignDialogOpen, setCampaignDialogOpen] = React.useState(false);
    const { formatMessage } = useIntl();

    const {
        data: supplierData,
        loading: supplierLoading,
        startPolling,
        stopPolling,
        refetch,
    } = useQuery(getSuppliersQuery, {
        variables: {
            input: {
                ids: group.supplierIDs,
            },
        },
        // when we federate categories/business units, we will need to change this to "all" as tenants without procurement entities will fail the call
        errorPolicy: "all",
        skip: !group.supplierIDs.length,
    });

    const campaignId = group.campaignId;

    const handleCampaignsClick = React.useCallback(() => {
        setCampaignDialogOpen((oldDialogOpen) => !oldDialogOpen);
    }, [setCampaignDialogOpen]);

    // change the id to the group campaign id and skip if campaign id is not present
    const { data: assessmentData } = useQuery(getAssessmentsInCampaignQuery, {
        variables: {
            input: {
                id: campaignId ?? "",
            },
        },
        skip: !campaignId,
    });

    const { data: usersData } = useQuery(getUsersQuery, {
        variables: { input: { terms: "" } },
        onError: (error) => {
            Sentry.captureException(error, { tags: { app: "social-risk-app", message: "Failed to get all users" } });
        },
    });

    const possibleUsers = React.useMemo(() => {
        if (!usersData?.getUsers?.result) {
            return {};
        }
        return usersData?.getUsers?.result.reduce(
            (acc: Record<string, GroupPageTable_UserFragment>, user: GroupPageTable_UserFragment) => {
                acc[user.id] = user;
                return acc;
            },
            {}
        );
    }, [usersData]);

    const rawSuppliers = supplierData?.getSuppliersByIds.suppliers;
    const assessments = assessmentData?.getCampaign.campaign.assessments;
    const supplierRows = React.useMemo(
        () => toSupplierRows(rawSuppliers ?? [], assessments ?? [], possibleUsers, formatMessage),
        [rawSuppliers, assessments, possibleUsers, formatMessage]
    );

    const { data: latestSpendInfoData } = useQuery(getLatestSpendInfoQuery);
    const spendColumnName = latestSpendInfoData?.getLatestSpendInfo.latestSpendInfo.columnName;

    const columns = useGetColumns(
        group.additionalColumns,
        campaignId,
        group.id,
        spendColumnName,
        startPolling,
        stopPolling,
        isEditor,
        refetch
    );

    const getCellCustomClassName = React.useCallback((params: GridCellParams) => {
        if (params.field === "country") {
            if (params.row.productionCountries !== null && params.row.productionCountries.length > 0) {
                return "greyed-out";
            }
        }
        return "";
    }, []);

    const noSuppliers = supplierRows.length === 0;
    const slots = React.useMemo(
        () => ({
            toolbar: () => (
                <CustomToolbar
                    handleCampaignsClick={handleCampaignsClick}
                    campaignId={campaignId ?? undefined}
                    createCampaignDisabled={noSuppliers}
                    isEditor={isEditor}
                />
            ),
        }),
        [handleCampaignsClick, campaignId, noSuppliers, isEditor]
    );

    if (!columns.length) {
        return <Skeleton height={600} width="100%" />;
    }

    return (
        <>
            <DataGridPro
                rows={supplierRows}
                columns={columns}
                scrollbarSize={0}
                getCellClassName={getCellCustomClassName}
                initialState={initialState}
                pageSizeOptions={pageSizeOptions}
                getRowId={getRowId}
                disableRowSelectionOnClick
                disableColumnReorder
                disableColumnResize
                disableColumnPinning
                disableColumnMenu
                slots={slots}
                pinnedColumns={pinnedColumns}
                columnGroupingModel={columnGroupingModel(formatMessage)}
                columnGroupHeaderHeight={28}
                sx={tableStyling}
                pagination
                loading={supplierLoading}
            />
            {campaignDialogOpen && (
                <CampaignCreationDialog open={campaignDialogOpen} onClose={handleCampaignsClick} group={group} />
            )}
        </>
    );
};

interface CustomToolbarProps {
    handleCampaignsClick: () => void;
    campaignId?: string;
    createCampaignDisabled: boolean;
    isEditor: boolean;
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({
    handleCampaignsClick,
    campaignId,
    createCampaignDisabled,
    isEditor,
}) => {
    const tooltipMessage = !isEditor ? (
        <FormattedMessage defaultMessage="Your permissions do not allow creating a campaign" />
    ) : createCampaignDisabled ? (
        <FormattedMessage defaultMessage="The group must have at least one supplier to create a campaign" />
    ) : null;

    const navigate = useOutgoingNavigate();
    return (
        <GridToolbarContainer>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center" p={2}>
                <Stack direction="row" columnGap={1} width="50%" alignItems="center">
                    <GridToolbarTotalItemsContainer />
                    <Divider variant="fullWidth" orientation="vertical" flexItem sx={{ height: "unset" }} />
                    <GridToolbarSearchField />
                </Stack>
                {campaignId ? (
                    <Button
                        startIcon={<PaperAirplane />}
                        onClick={() => navigate(`/assessments/campaigns/${campaignId}/`)}
                        color="secondary"
                        size="small"
                    >
                        <FormattedMessage defaultMessage="View campaign" description="View campaign button text" />
                    </Button>
                ) : (
                    <Tooltip title={tooltipMessage}>
                        <span>
                            <Button
                                startIcon={<PaperAirplane />}
                                onClick={handleCampaignsClick}
                                color="secondary"
                                disabled={createCampaignDisabled || !isEditor}
                                size="small"
                            >
                                <FormattedMessage
                                    defaultMessage="Invite to campaign"
                                    description="Invite to campaign button text"
                                />
                            </Button>
                        </span>
                    </Tooltip>
                )}
            </Stack>
        </GridToolbarContainer>
    );
};
