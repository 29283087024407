import { getLocale } from "@ignite-analytics/locale";

import { RiskOption } from "./types";

export const getLocalName = (option: RiskOption | null) => {
    if (!option) return "";

    if (getLocale() === "nb-NO") {
        return option.name_no;
    }
    return option.name_en;
};

export const getLabel = (option: RiskOption | null) => {
    if (!option) return "";
    return `${option.code}: ${getLocalName(option)}`;
};
