import { gql, useQuery } from "@apollo/client";
import { Dot } from "@ignite-analytics/icons";
import { Avatar, Stack, Typography } from "@mui/material";

import { Activities_UserFragment } from "@/gql/graphql";

interface UserTimestampProps {
    user: Activities_UserFragment | undefined;
}

const getCurrentCustomer = gql(`
    query getCurrentCustomer {
        getCurrentCustomer {
            name
        }
    }
`);

export const UserTimestamp: React.FC<UserTimestampProps> = ({ user }) => {
    const { data: customerData } = useQuery(getCurrentCustomer);
    const currentTenantName = customerData?.getCurrentCustomer?.name;

    return (
        <Stack direction="row" spacing={1} minWidth="30%">
            <Avatar src="" sx={{ width: 24, height: 24 }}>
                {user ? (
                    <Typography color="textSecondary" variant="textXs">
                        {user.initials}
                    </Typography>
                ) : (
                    <Dot fontSize="large" />
                )}
            </Avatar>
            <Typography
                variant="textSm"
                fontWeight={user ? 500 : 400}
                width="140px"
                sx={{ fontStyle: user ? "normal" : "italic" }}
            >
                {user ? user.fullName : currentTenantName}
            </Typography>
        </Stack>
    );
};
