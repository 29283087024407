import { DotsHorizontal } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Box, IconButton, Menu, MenuItem, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { Activities_UserFragment, Activity_NoteFragment } from "@/gql/graphql";
import { TRACK_PREFIX } from "@/lib/track";
import { useUser } from "@/providers/UserContext";

import { DeleteNoteModal } from "./DeleteNoteModal";
import { EditNote } from "./EditNote";
import { Activity } from "./types";

const MAX_DISPLAY_NOTE_LENGTH = 200;

interface ActivityDetailNoteProps {
    activity: Activity;
    isEditor: boolean;
    relatedUser: Activities_UserFragment | undefined;
}

export const ActivityDetailNote: React.FC<ActivityDetailNoteProps> = ({ activity, isEditor, relatedUser }) => {
    const [expanded, setExpanded] = useState(false);
    const [noteActionsAnchorEl, setNoteActionsAnchorEl] = useState<null | HTMLElement>(null);
    const [showOptions, setShowOptions] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const currentUser = useUser();

    const handleNoteMouseEnter = () => {
        if (activity.type === "Note") {
            setShowOptions(true);
        }
    };

    if (activity.type === "Note" || activity.type === "Risk" || activity.type === "OnboardingEvaluated") {
        const noteContent = activity.content as Activity_NoteFragment;
        if (!noteContent.note) return null;
        const needsExpansion = noteContent.note.length > MAX_DISPLAY_NOTE_LENGTH;
        const text =
            !needsExpansion || expanded ? noteContent.note : `${noteContent.note.slice(0, MAX_DISPLAY_NOTE_LENGTH)}...`;

        return (
            <div
                onMouseEnter={handleNoteMouseEnter}
                onMouseLeave={() => {
                    setShowOptions(false);
                    setNoteActionsAnchorEl(null);
                }}
                style={{ position: "relative" }}
            >
                {isEditing ? (
                    <EditNote oldNote={noteContent} onClose={() => setIsEditing(false)} />
                ) : (
                    <Paper>
                        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                            <Stack p={1.5}>
                                <Typography variant="textSm">{text}</Typography>
                                {needsExpansion && (
                                    <Typography
                                        variant="textSm"
                                        color="text.text-helper"
                                        sx={{ cursor: "pointer", display: "inline-block" }}
                                        onClick={() => setExpanded(!expanded)}
                                    >
                                        {expanded ? (
                                            <FormattedMessage defaultMessage="Show less" />
                                        ) : (
                                            <FormattedMessage defaultMessage="Show more" />
                                        )}
                                    </Typography>
                                )}
                            </Stack>
                            {showOptions && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: 6,
                                        right: 8,
                                    }}
                                >
                                    <Tooltip
                                        title={
                                            currentUser.id !== noteContent.createdBy || !isEditor
                                                ? "You do not have permission to edit this note"
                                                : undefined
                                        }
                                        placement="top"
                                    >
                                        <IconButton
                                            disabled={currentUser.id !== noteContent.createdBy || !isEditor}
                                            onClick={(event) => {
                                                track(`${TRACK_PREFIX}: Open Note Actions`);
                                                setNoteActionsAnchorEl(event.currentTarget);
                                            }}
                                            color="secondary"
                                            size="xsmall"
                                            sx={{
                                                // This is a workaround to make the IconButton send PointerEvent when disabled
                                                "&.Mui-disabled": {
                                                    pointerEvents: "auto",
                                                },
                                            }}
                                        >
                                            <DotsHorizontal />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        anchorEl={noteActionsAnchorEl}
                                        open={Boolean(noteActionsAnchorEl)}
                                        onClose={() => {
                                            setShowOptions(false);
                                            setNoteActionsAnchorEl(null);
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                setIsEditing(true);
                                                setNoteActionsAnchorEl(null);
                                            }}
                                        >
                                            <FormattedMessage defaultMessage="Edit" />
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                setIsDeleting(true);
                                                setNoteActionsAnchorEl(null);
                                            }}
                                        >
                                            <FormattedMessage defaultMessage="Delete" />
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            )}
                        </Stack>
                    </Paper>
                )}
                {isDeleting && (
                    <DeleteNoteModal
                        relatedUser={relatedUser}
                        onClose={() => setIsDeleting(false)}
                        note={noteContent}
                    />
                )}
            </div>
        );
    }
    return null;
};
