import { useQuery } from "@apollo/client";
import { Button, Grid2, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { EstimatedRiskScore } from "@/components/EstimatedRiskScore";
import { GroupCreationDialog } from "@/components/GroupCreationDialog";
import { GroupsList } from "@/components/GroupsList";
import { OverallProgress } from "@/components/LinerProgress/OverallProgress";
import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { usePermission } from "@/lib/permissions";

import { BetaTagWithInfoHover } from "../../components/BetaTag/betaTagWithHover";

const getSupplierGroupQuery = graphql(`
    query OverviewPage_GetGroups($input: GetSupplierGroupsInput!) {
        getSupplierGroups(input: $input) {
            supplierGroups {
                groups {
                    ...GroupsList_SupplierGroup
                }
                progress {
                    ManualEnrichment
                    IgniteEnrichment
                    EmptyEnrichment
                }
            }
            riskEstimate
        }
    }
`);

export const OverviewPage: React.FC = () => {
    const [groupCreationDialogOpen, setGroupCreationDialogOpen] = React.useState(false);
    useSetBreadcrumbs("Overview");

    const handleCreateGroup = () => {
        setGroupCreationDialogOpen(true);
    };

    const isEditor = usePermission({ object: "general", relation: "write" });

    const { data: groupsData, loading } = useQuery(getSupplierGroupQuery, {
        fetchPolicy: "cache-and-network",
        variables: {
            input: {},
        },
    });

    return (
        <Stack>
            <Stack direction="row" justifyContent="space-between" pb={3} alignContent="center">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="displayXs" fontWeight={700}>
                        <FormattedMessage
                            defaultMessage="Social risk management"
                            description="Social risk management header"
                        />
                    </Typography>
                    <BetaTagWithInfoHover />
                </Stack>
                <NoPermissionTooltip
                    hasPermission={isEditor}
                    placement="top"
                    message={<FormattedMessage defaultMessage="Your permissions do not allow creating a group" />}
                >
                    <Button color="primary" disabled={!isEditor} onClick={handleCreateGroup}>
                        <FormattedMessage
                            defaultMessage="Create supplier group"
                            description="Create supplier group primary action"
                        />
                    </Button>
                </NoPermissionTooltip>
            </Stack>
            <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 6 }}>
                    <EstimatedRiskScore
                        loading={loading}
                        level={groupsData?.getSupplierGroups.riskEstimate || undefined}
                    />
                </Grid2>
                <Grid2 size={{ xs: 6 }} sx={{ justifyContent: "center" }}>
                    <OverallProgress
                        manualEnrichmentProgress={
                            groupsData?.getSupplierGroups.supplierGroups.progress.ManualEnrichment || 0
                        }
                        igniteEnrichmentProgress={
                            groupsData?.getSupplierGroups.supplierGroups.progress.IgniteEnrichment || 0
                        }
                        emptyEnrichmentProgress={
                            groupsData?.getSupplierGroups.supplierGroups.progress.EmptyEnrichment || 0
                        }
                    />
                </Grid2>
                <Grid2 size={{ xs: 12 }}>
                    <GroupsList
                        isEditor={isEditor}
                        loading={loading}
                        groups={groupsData?.getSupplierGroups.supplierGroups.groups ?? []}
                        handleCreateGroup={handleCreateGroup}
                    />
                </Grid2>
            </Grid2>
            {
                // Unmount the dialog when we close it so the state resets
                groupCreationDialogOpen && <GroupCreationDialog onClose={() => setGroupCreationDialogOpen(false)} />
            }
        </Stack>
    );
};
