import { Activity_NoteFragment, SupplierDrawer_SupplierFragment } from "@/gql/graphql";

import { Activity, ActivityType } from "./types";

export function aggregateActivityData(supplier: SupplierDrawer_SupplierFragment): Activity[] {
    return [
        {
            content: {
                id: supplier.id,
                name: supplier.name,
                createdAt: supplier.onboarding?.createdAt,
            },
            type: "OnboardingInitiated" as ActivityType,
            timestamp: supplier.onboarding?.createdAt ?? "",
            relatedUserId: "",
        },
        {
            content: {
                name: supplier.name,
                status: supplier.onboarding?.status,
                approverId: supplier.onboarding?.approverId,
                evaluatedAt: supplier.onboarding?.evaluatedAt,
            },
            type: "OnboardingEvaluated" as ActivityType,
            timestamp: supplier.onboarding?.evaluatedAt ?? "",
            relatedUserId: supplier.onboarding?.approverId ?? "",
        },
        ...supplier.notes.map((note: Activity_NoteFragment) => ({
            content: {
                __typename: "Note",
                id: note.id,
                note: note.note,
                supplierId: note.supplierId,
                createdBy: note.createdBy,
                createdAt: note.createdAt,
                updatedBy: note.updatedBy,
                updatedAt: note.updatedAt,
                type: note.type,
                reactions: note.reactions?.map((reaction) => ({
                    emojiCode: reaction.emojiCode,
                    userIDs: reaction.userIDs,
                })) as { emojiCode: string; userIDs: string[] }[],
                metadata: note.metadata,
            } as Activity_NoteFragment,
            type: (note.type === "risk" ? "Risk" : "Note") as ActivityType,
            timestamp: note.createdAt,
            relatedUserId: note.createdBy,
        })),
    ];
}

export function isValidDate(dateString: string) {
    const date = new Date(dateString ?? "");
    if (!date || isNaN(date.getTime())) {
        return false;
    }
    const invalidDate = new Date(Date.UTC(2001, 0, 1, 0, 0, 0));
    return date.getTime() !== invalidDate.getTime();
}
