import { useMutation, useQuery } from "@apollo/client";
import { Dot } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { LinearProgress, Paper, SelectChangeEvent, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { DocumentsList_AssessmentsFileFragment, DocumentsList_SupplierFileFragment } from "@/gql/graphql";
import { getNameAndContentType } from "@/lib/files";
import { TRACK_PREFIX } from "@/lib/track";
import { useAlert } from "@/providers";

import { DocumentActions } from "./DocumentActions";
import { DocumentTypeSelector } from "./DocumentTypeSelector";
import { UploadButton } from "./UploadButton";

export type DocumentListFiles = DocumentsList_SupplierFileFragment | DocumentsList_AssessmentsFileFragment;

type Props = {
    supplierId: string;
    isEditor: boolean;
    refetch: () => void;
};

const DocumentsList_UpdateSupplierFileMetadataMutation = graphql(`
    mutation DocumentsList_UpdateSupplierFileMetadataMutation($input: UpdateSupplierFileMetadataInput!) {
        updateSupplierFileMetadata(input: $input) {
            fileMetaJson
        }
    }
`);

const DocumentsList_UpdateAssessmentsFileMetadataMutation = graphql(`
    mutation DocumentsList_UpdateAssessmentsFileMetadataMutation($input: UpdateAssessmentsFileMetadataInput!) {
        updateAssessmentsFileMetadata(input: $input) {
            fileMetaJson
        }
    }
`);

const DocumentsList_SupplierQuery = graphql(`
    query DocumentsList_SupplierQuery($input: GetSupplierInput!) {
        getSupplier(input: $input) {
            supplier {
                id
                files {
                    ...DocumentsList_SupplierFile
                }
                assessmentsFiles {
                    ...DocumentsList_AssessmentsFile
                }
            }
        }
    }
`);

graphql(`
    fragment DocumentsList_SupplierFile on SupplierFile {
        name
        createdAt
        metaJson
        size
        supplierId
        ...DocumentsActions_SupplierFile
    }

    fragment DocumentsList_AssessmentsFile on AssessmentsFile {
        name
        createdAt
        metaJson
        size
        referenceId
    }
`);

const isScanning = (file: DocumentListFiles) => {
    if (!file.metaJson) return false;
    const { av_scanned } = JSON.parse(file.metaJson);
    return av_scanned === "FALSE";
};

export const DocumentsTab: React.FC<Props> = ({ supplierId, isEditor, refetch }) => {
    const { formatMessage, formatDate } = useIntl();
    const { alertUser } = useAlert();

    const { data, loading } = useQuery(DocumentsList_SupplierQuery, {
        variables: {
            input: {
                id: supplierId,
            },
        },
        onError: (error) => {
            alertUser({
                value: error.message,
                severity: "error",
            });
        },
        skip: !supplierId,
    });

    const supplierFiles = data?.getSupplier.supplier?.files || [];
    const assessmentsFiles = data?.getSupplier.supplier?.assessmentsFiles || [];
    const files: DocumentListFiles[] = [...supplierFiles, ...assessmentsFiles];

    const [updateSupplierFileMetadata] = useMutation(DocumentsList_UpdateSupplierFileMetadataMutation);
    const [updateAssessmentsFileMetadata] = useMutation(DocumentsList_UpdateAssessmentsFileMetadataMutation);

    const documentsSorted = files.sort((a, b) => {
        const createdAtA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
        const createdAtB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
        return createdAtB - createdAtA;
    });

    const handleSelectChange = (file: DocumentListFiles, event: SelectChangeEvent) => {
        const { value } = event.target;
        const errorMessage = formatMessage(
            {
                defaultMessage: "Failed to change document type for: {file}",
                description: "File metadata update fail message",
            },
            { file: file.name }
        );
        if (file && file.metaJson) {
            const updatedFile = {
                ...file,
                metaJson: JSON.stringify({
                    ...JSON.parse(file.metaJson),
                    doctype: value,
                }),
            };
            if (file.__typename === "AssessmentsFile") {
                updateAssessmentsFileMetadata({
                    variables: {
                        input: {
                            fileName: updatedFile.name,
                            fileMetaJson: updatedFile.metaJson,
                            referenceId: file.referenceId,
                        },
                    },
                    onError: () => {
                        alertUser({
                            value: errorMessage,
                            severity: "error",
                        });
                    },
                });
            } else {
                updateSupplierFileMetadata({
                    variables: {
                        input: {
                            fileName: updatedFile.name,
                            fileMetaJson: updatedFile.metaJson,
                            supplierId: file.supplierId,
                        },
                    },
                    onError: () => {
                        alertUser({
                            value: errorMessage,
                            severity: "error",
                        });
                    },
                });
            }
            track(`${TRACK_PREFIX}: Selected Document Type`, { supplierId, documentType: value });
        }
    };

    const convertDocumentOrigin = (metaJson: string) => {
        const { origin } = JSON.parse(metaJson);
        switch (origin) {
            case "supplier-page":
                return formatMessage({
                    defaultMessage: "Upload",
                    description: "Documents uploaded from supplier-page",
                });
            case "assessment":
                return formatMessage({
                    defaultMessage: "Assessments",
                    description: "Documents uploaded from assessment answer",
                });
            default:
                return formatMessage({ defaultMessage: "Unknown", description: "Unknown document upload origin" });
        }
    };

    return (
        <Stack spacing={2} pb={6}>
            <Typography variant="textXl" fontWeight={500}>
                <FormattedMessage defaultMessage="Documents" description="Documents header" />
            </Typography>
            {loading ? (
                <>
                    <Skeleton variant="rectangular" height={100} />
                    <Skeleton variant="rectangular" height={100} />
                    <Skeleton variant="rectangular" height={100} />
                </>
            ) : (
                <>
                    {documentsSorted?.map((file) => {
                        const { originalFileName } = getNameAndContentType(file);
                        return isScanning(file) ? (
                            <Paper sx={{ padding: 2, width: "100%" }}>
                                <Stack spacing={1}>
                                    <LinearProgress variant="indeterminate" />
                                    <Typography
                                        variant="textSm"
                                        fontWeight={500}
                                        sx={{ overflowWrap: "break-word", wordBreak: "break-all" }}
                                    >
                                        <FormattedMessage
                                            defaultMessage="Uploading {file}..."
                                            values={{ file: decodeURIComponent(originalFileName) }}
                                        />
                                    </Typography>
                                </Stack>
                            </Paper>
                        ) : (
                            <Paper key={`${file.name}+${file.metaJson}`} sx={{ padding: 2 }}>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Stack spacing={1} pr={2}>
                                        <Typography
                                            variant="textSm"
                                            fontWeight={500}
                                            sx={{ overflowWrap: "break-word", wordBreak: "break-all" }}
                                        >
                                            {decodeURIComponent(originalFileName)}
                                        </Typography>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Stack alignItems="center">
                                                {file.metaJson && (
                                                    <NoPermissionTooltip hasPermission={isEditor}>
                                                        <DocumentTypeSelector
                                                            file={file}
                                                            onChange={handleSelectChange}
                                                            isEditor={isEditor}
                                                        />
                                                    </NoPermissionTooltip>
                                                )}
                                            </Stack>
                                            <Stack direction="row" alignItems="center">
                                                <Typography variant="textXs" color="textTextHelper">
                                                    {file.createdAt !== null ? formatDate(file.createdAt) : ""}
                                                </Typography>
                                                <Dot />
                                                {file.metaJson && (
                                                    <Typography variant="textXs" color="textTextHelper">
                                                        {convertDocumentOrigin(file.metaJson)}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <DocumentActions
                                        isEditor={isEditor}
                                        file={file}
                                        supplierId={supplierId}
                                        refetch={refetch}
                                    />
                                </Stack>
                            </Paper>
                        );
                    })}
                    {isEditor && <UploadButton isEditor={isEditor} supplierId={supplierId} refetch={refetch} />}
                </>
            )}
        </Stack>
    );
};
