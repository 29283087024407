import { useMutation } from "@apollo/client";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    Typography,
    DialogActions,
    Button,
    Paper,
    Grid,
    Avatar,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { Activities_UserFragment, Activity_NoteFragment } from "@/gql/graphql";
import { track, TRACK_PREFIX } from "@/lib/track";
import { useAlert } from "@/providers";

const deleteNoteDocument = graphql(`
    mutation Notes_DeleteNoteMutation($input: DeleteSupplierNoteInput!) {
        deleteSupplierNote(input: $input) {
            deletedId
        }
    }
`);

dayjs.extend(timezone);
dayjs.extend(relativeTime);

export const DeleteNoteModal: React.FC<{
    onClose: () => void;
    note: Activity_NoteFragment;
    relatedUser: Activities_UserFragment | undefined;
}> = ({ onClose, note, relatedUser }) => {
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();
    const [deleteNote] = useMutation(deleteNoteDocument, {
        variables: { input: { id: note.id } },
        update: (cache, { data }) => {
            const deletedId = data?.deleteSupplierNote?.deletedId;
            if (deletedId) {
                cache.modify({
                    id: cache.identify({ __typename: "Supplier", id: note.supplierId }),
                    fields: {
                        notes(existingNotesRefs, { readField }) {
                            return existingNotesRefs.filter(
                                (note: Activity_NoteFragment) => readField("id", note) !== deletedId
                            );
                        },
                    },
                });
            }
        },
        onCompleted: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Deleted note successfully",
                    description: "Delete supplier note successful toast",
                }),
                severity: "success",
            });
            onClose();
        },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to delete supplier note",
                    description: "Delete supplier note fail toast",
                }),
                severity: "error",
            });
        },
    });
    return (
        <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>
                <FormattedMessage defaultMessage="Delete note" />
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Typography variant="body2">
                        <FormattedMessage defaultMessage="Are you sure you want to delete this note? This will delete it for all users permanently." />
                    </Typography>
                    <NoteCard
                        note={note}
                        userDisplayName={relatedUser?.fullName ?? "Unknown user"}
                        initials={relatedUser?.initials ?? ""}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="text" color="secondary">
                    <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button
                    onClick={() => {
                        track(`${TRACK_PREFIX}: Delete Note`);
                        deleteNote();
                    }}
                    variant="contained"
                    color="primary"
                >
                    <FormattedMessage defaultMessage="Delete" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const NoteCard: React.FC<{ note: Activity_NoteFragment; userDisplayName: string; initials: string }> = ({
    note,
    userDisplayName,
    initials,
}) => {
    const timezone = dayjs.tz.guess();
    return (
        <Paper sx={{ padding: 2, minWidth: "280px" }}>
            <Grid item>
                <Stack direction="row" alignItems="start" spacing={1}>
                    <Avatar sx={{ bgcolor: (theme) => theme.palette.grey[50], height: "40px", width: "40px" }}>
                        <Typography sx={{ fontSize: "1rem" }}>{initials}</Typography>
                    </Avatar>
                    <Stack flexGrow={3}>
                        <Stack direction="row" alignItems="start" justifyContent="space-between">
                            <Stack direction="column" alignItems="start" minHeight="50px">
                                <Typography variant="textSm" fontWeight={800}>
                                    {userDisplayName}
                                </Typography>
                                <Typography variant="textSm" color="text.secondary">
                                    <FormattedMessage
                                        defaultMessage="{time} ago"
                                        values={{
                                            time: dayjs(dayjs(note.createdAt).tz(timezone)).fromNow(true),
                                        }}
                                    />
                                </Typography>
                            </Stack>
                        </Stack>
                        <Typography variant="textSm" sx={{ marginTop: 2 }}>
                            {note.note}
                        </Typography>
                    </Stack>
                </Stack>
            </Grid>
        </Paper>
    );
};
