import { gql, useMutation, useQuery } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import { Button, Stack, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { Activities_UserFragment, Activity_NoteFragmentDoc, SupplierDrawer_SupplierFragment } from "@/gql/graphql";
import { TRACK_PREFIX } from "@/lib/track";
import { useAlert } from "@/providers";

import { ActivityDetail } from "./ActivityDetail";
import { UserTimestamp } from "./UserTimestamp";
import { aggregateActivityData, isValidDate } from "./utils";

gql(`
    fragment Activity_Note on Note {
        id
        note
        createdBy
        createdAt
        updatedBy
        updatedAt
        supplierId
        type
        reactions {
            emojiCode
            userIDs
        }
        metadata {
            prevValue
            newValue
        }
    }

    fragment Onboarding on Onboarding {
        status
        approverId
        evaluatedAt
        createdAt
    }
`);

const getUsersDocument = gql(`
    query getUsers($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                ...Activities_User
            }
        }
    }

    fragment Activities_User on RoleUser {
        id
        firstName
        lastName
        fullName
        initials
    }
`);

const createNoteDocument = graphql(`
    mutation Notes_CreateNoteMutation($input: CreateSupplierNoteInput!) {
        createSupplierNote(input: $input) {
            note {
                ...Activity_Note
            }
        }
    }
`);

interface ActivitiesTabProps {
    supplier: SupplierDrawer_SupplierFragment;
    isEditor: boolean;
}

export const ActivitiesTab: React.FC<ActivitiesTabProps> = ({ supplier, isEditor }) => {
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();
    const [newNote, setNewNote] = useState("");

    const { data: usersData } = useQuery(getUsersDocument, {
        variables: { input: {} },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Error fetching users",
                    description: "Error fetching users alert message",
                }),
                severity: "error",
            });
        },
    });

    const [createNote] = useMutation(createNoteDocument, {
        variables: { input: { supplierId: supplier.id, note: newNote } },
        refetchQueries: ["AppRoutes_GetSupplier"],
        update: (cache, { data }) => {
            if (!data?.createSupplierNote?.note) {
                return;
            }
            cache.modify({
                id: cache.identify(supplier),
                fields: {
                    notes(existingNotes = []) {
                        const newNoteRef = cache.writeFragment({
                            data: data.createSupplierNote.note,
                            fragment: Activity_NoteFragmentDoc,
                            fragmentName: "Activity_Note",
                        });
                        return [newNoteRef, ...existingNotes];
                    },
                },
            });
        },
        onCompleted: () => {
            setNewNote("");
            track(`${TRACK_PREFIX}: Created supplier note`, { supplierId: supplier.id, noteLength: newNote.length });
        },
    });
    const users: Activities_UserFragment[] = usersData?.getUsers?.result ?? [];

    const activities = aggregateActivityData(supplier)
        .filter((activity) => isValidDate(activity.timestamp))
        .sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp));

    return (
        <Stack spacing={1} width="100%">
            <Typography variant="textXl" fontWeight={500}>
                <FormattedMessage defaultMessage="Notes & activity" description="Notes tab header" />
            </Typography>
            <Stack direction="row" spacing={1} width="100%" alignItems="center">
                {isEditor && (
                    <>
                        <TextField
                            placeholder={formatMessage({ defaultMessage: "Leave a note" })}
                            multiline
                            value={newNote}
                            onChange={(e) => setNewNote(e.target.value)}
                            maxRows={20}
                            sx={{
                                flex: 1,
                                "& .MuiInputBase-root": {
                                    backgroundColor: "transparent",
                                },
                                "& .MuiInputBase-input": {
                                    backgroundColor: "white",
                                },
                                "& .MuiInputBase-multiline textarea": {
                                    paddingY: "9px",
                                },
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && !e.shiftKey && newNote) {
                                    track("${TRACK_PREFIX}: Created Supplier Note On With Keyboard Shortcut");
                                    e.preventDefault();
                                    createNote();
                                }
                                if (e.key === "Enter" && e.shiftKey) {
                                    setNewNote(newNote + "\n");
                                }
                            }}
                        />
                        <Button color="primary" size="small" disabled={!newNote} onClick={() => createNote()}>
                            <FormattedMessage defaultMessage="Post" />
                        </Button>
                    </>
                )}
            </Stack>

            {activities.map((activity, index) => {
                const userId = activity.relatedUserId;
                const relatedUser = userId ? users.find((user) => user.id === userId) : undefined;

                return (
                    <TableRow
                        id={`row-${index}`}
                        key={index}
                        sx={{
                            width: "100%",
                            border: "none",
                            position: "relative",
                            "&::before": {
                                content: '""',
                                position: "absolute",
                                left: "35px",
                                top: 16,
                                bottom: index === activities.length - 1 ? 0 : -24,
                                width: "1px",
                                backgroundColor: (theme) => theme.palette.tokens?.border.border200,
                                zIndex: -1,
                                display: activities.length < 2 ? "none" : "block",
                            },
                        }}
                    >
                        <TableCell
                            style={{
                                display: "table-cell",
                                width: "30%",
                                verticalAlign: "top",
                            }}
                        >
                            <UserTimestamp user={relatedUser} />
                        </TableCell>
                        <TableCell width="100%" sx={{ verticalAlign: "top" }}>
                            <Stack gap={1.5}>
                                <ActivityDetail
                                    activity={activity}
                                    socialRisk={supplier.risk?.social ?? undefined}
                                    isEditor={isEditor}
                                    relatedUser={relatedUser}
                                />
                            </Stack>
                        </TableCell>
                    </TableRow>
                );
            })}
        </Stack>
    );
};
