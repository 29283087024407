import { useMutation } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { Activity_NoteFragment, Notes_UpdateNoteMutationDocument } from "@/gql/graphql";
import { TRACK_PREFIX } from "@/lib/track";
import { useAlert } from "@/providers";

graphql(`
    mutation Notes_UpdateNoteMutation($input: UpdateSupplierNoteInput!) {
        updateSupplierNote(input: $input) {
            updatedNote {
                ...Activity_Note
            }
        }
    }
`);

interface EditNoteProps {
    oldNote: Activity_NoteFragment;
    onClose: () => void;
}

export const EditNote: React.FC<EditNoteProps> = ({ oldNote, onClose }) => {
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();
    const [newNote, setNewNote] = useState(oldNote.note);
    const [updateNote] = useMutation(Notes_UpdateNoteMutationDocument, {
        variables: { input: { id: oldNote.id, note: newNote } },
        update: (cache, { data }) => {
            const updatedNote = data?.updateSupplierNote?.updatedNote;
            if (updatedNote) {
                cache.modify({
                    id: cache.identify({ __typename: "Note", id: oldNote.id }),
                    fields: {
                        note() {
                            return updatedNote.note;
                        },
                        updatedAt(existingUpdatedAt = null) {
                            return updatedNote.updatedAt ?? existingUpdatedAt;
                        },
                    },
                });
            }
        },
        onCompleted: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Note updated successfully",
                    description: "Success toast for supplier note edit",
                }),
                severity: "success",
            });
            track(`${TRACK_PREFIX}: Updated note`);
            onClose();
        },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to update note",
                    description: "Error toast for supplier note edit",
                }),
                severity: "error",
            });
        },
    });

    return (
        <Stack>
            <TextField
                multiline
                fullWidth
                maxRows={12}
                placeholder="Type your note here"
                variant="outlined"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                sx={{
                    borderRadius: 2,
                    "& .MuiOutlinedInput-root": {
                        padding: 0,
                    },
                }}
                inputProps={{ maxLength: 1000 }}
            />
            <Stack direction="row" paddingTop={1} spacing={1} justifyContent="flex-end">
                <Button variant="text" color="secondary" size="xsmall" onClick={() => onClose()}>
                    <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button variant="text" color="primary" size="xsmall" disabled={!newNote} onClick={() => updateNote()}>
                    <FormattedMessage defaultMessage="Save" description="Save button" />
                </Button>
            </Stack>
        </Stack>
    );
};
