import { X } from "@ignite-analytics/icons";
import { formatDateAsUTC } from "@ignite-analytics/locale";
import { Button, Card, Chip, chipClasses, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { track, TRACK_PREFIX } from "@/lib/track";

import { ExternalAnswers, ExternalAnswersProps } from "./External/ExternalAnswers";
import { InternalAnswers, InternalAnswersProps } from "./Internal/InternalAnswers";

type AnswerRowProps = {
    name: string;
    dueDate?: string;
    score?: number;
    props: ExternalAnswersProps | InternalAnswersProps;
};

const isExternalAnswersProps = (props: ExternalAnswersProps | InternalAnswersProps): props is ExternalAnswersProps => {
    return (props as ExternalAnswersProps).assessmentId !== undefined;
};

export const AnswerRow: React.FC<AnswerRowProps> = ({ name, dueDate, score, props }) => {
    const { formatMessage } = useIntl();
    const [open, setOpen] = useState(false);

    const handleToggleOpen = () => {
        track(`${TRACK_PREFIX}: Opened Assessment Card`, {
            type: isExternalAnswersProps(props) ? "External" : "Internal",
        });
        setOpen(!open);
    };

    const answerComponent = isExternalAnswersProps(props) ? (
        <ExternalAnswers assessmentId={props.assessmentId} questionnaireId={props.questionnaireId} />
    ) : (
        <InternalAnswers
            questionnaireId={props.questionnaireId}
            campaignId={props.campaignId}
            supplierId={props.supplierId}
        />
    );

    const dot = "•";

    return (
        <Stack height="100%">
            <Card sx={{ flexGrow: 1 }}>
                {!open ? (
                    <Stack
                        direction="row"
                        padding={2}
                        alignItems="center"
                        justifyContent="space-between"
                        onClick={handleToggleOpen}
                        sx={{ cursor: "pointer" }}
                    >
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="textLg" fontWeight={500}>
                                {name}
                            </Typography>
                            <Typography variant="textSm" color="textTextBody">
                                {!dueDate && formatMessage({ defaultMessage: "(All)" })}
                            </Typography>
                        </Stack>

                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="textSm" color="textTextBody">
                                <FormattedMessage defaultMessage="Score" />
                            </Typography>
                            <Chip
                                size="small"
                                color="primary"
                                label={score ? score : "-"}
                                sx={{
                                    minWidth: 30,
                                    borderRadius: 1,
                                    [`& .${chipClasses.icon}`]: {
                                        color: (theme) => theme.palette.secondary.dark,
                                    },
                                }}
                            />

                            <Typography fontSize={8} minWidth={8} textAlign="right" color="textTextBody">
                                {dueDate && dot}
                            </Typography>

                            <Typography variant="textSm" color="textTextBody" minWidth={80} align="center">
                                {dueDate ? formatDateAsUTC(dueDate) : "N/A"}
                            </Typography>
                            <Button color="secondary" size="small" onClick={handleToggleOpen}>
                                <FormattedMessage defaultMessage="View answers" description="View answers button" />
                            </Button>
                        </Stack>
                    </Stack>
                ) : (
                    <>
                        <Stack
                            direction="row"
                            padding={2}
                            alignItems="center"
                            justifyContent="space-between"
                            onClick={handleToggleOpen}
                            sx={{ cursor: "pointer" }}
                        >
                            <Typography variant="textLg" fontWeight={500}>
                                {name}
                            </Typography>
                            <IconButton onClick={handleToggleOpen} aria-label="Close">
                                <X />
                            </IconButton>
                        </Stack>
                        {answerComponent}
                    </>
                )}
            </Card>
        </Stack>
    );
};
