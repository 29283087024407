import { Stack } from "@mui/material";
import React from "react";

import { SuggestedStatusLevel } from "@/components/RiskStatus";
import { Activities_UserFragment } from "@/gql/graphql";

import { ActivityDetailNote } from "./ActivityDetailNote";
import { ActivityDetailSummary } from "./ActivityDetailSummary";
import { TimeStamp } from "./TimeStamp";
import { Activity } from "./types";

interface ActivityDetailProps {
    activity: Activity;
    socialRisk: SuggestedStatusLevel | undefined;
    isEditor: boolean;
    relatedUser: Activities_UserFragment | undefined;
}

export const ActivityDetail: React.FC<ActivityDetailProps> = ({ activity, socialRisk, isEditor, relatedUser }) => {
    return (
        <Stack spacing={1}>
            <ActivityDetailSummary activity={activity} socialRisk={socialRisk} />
            <TimeStamp timestamp={activity.timestamp} />
            <ActivityDetailNote activity={activity} isEditor={isEditor} relatedUser={relatedUser} />
        </Stack>
    );
};
