import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import { OnboardingStatus } from "@/gql/graphql";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

export const DisabledField = styled("div")(() => ({
    display: "flex",
    alignContent: "center",
    width: "100%",
    height: "100%",
    padding: "0 8px",
    alignItems: "center",
    boxSizing: "border-box",
}));

interface OnboardingChipProps {
    onboardingStatus?: OnboardingStatus;
    supplierId: string;
}

const getChipColor = (value?: OnboardingStatus) => {
    switch (value) {
        case "APPROVED":
            return "success";
        case "REJECTED":
            return "error";
        case "IN_ONBOARDING":
            return "warning";
        default:
            return undefined;
    }
};

export const OnboardingChip: React.FC<OnboardingChipProps> = ({ onboardingStatus, supplierId }) => {
    const { formatMessage } = useIntl();
    const navigate = useOutgoingNavigate();
    const location = useLocation();

    const getChipText = (value?: OnboardingStatus) => {
        switch (value) {
            case "APPROVED":
                return formatMessage({ defaultMessage: "Approved" });
            case "REJECTED":
                return formatMessage({ defaultMessage: "Rejected" });
            case "IN_ONBOARDING":
                return formatMessage({ defaultMessage: "In onboarding" });
            default:
                return "inherit";
        }
    };

    return (
        <Chip
            size="small"
            variant="status"
            color={getChipColor(onboardingStatus)}
            label={getChipText(onboardingStatus)}
            sx={{
                textTransform: "capitalize",
                "&:hover": {
                    bgcolor: (theme) => {
                        switch (onboardingStatus) {
                            case "APPROVED":
                                return theme.palette.success.light;
                            case "REJECTED":
                                return theme.palette.error.light;
                            case "IN_ONBOARDING":
                                return theme.palette.warning.light;
                            default:
                                return theme.palette.grey[300];
                        }
                    },
                },
            }}
            onClick={() => {
                navigate(`${location.pathname}/onboarding/${supplierId}`, {
                    replace: true,
                });
            }}
        />
    );
};

export const OnboardingField: React.FC<OnboardingChipProps> = ({ onboardingStatus, supplierId }) => {
    return (
        <DisabledField>
            <OnboardingChip onboardingStatus={onboardingStatus} supplierId={supplierId} />
        </DisabledField>
    );
};
